import { HomeIcon, PersonIcon, StatsIcon, SettingsIcon } from "components/Icons/Icons";
import SignInBasic from "views/Authentication/SignIn/SignInBasic";
import SignUp from "views/Authentication/SignUp/SignUpBasic";
import AllChecksData from "views/ManualModeration/AllChecksData";
import WaitChecksData from "views/ManualModeration/WaitChecksData";
import CheckCard from "views/ManualModeration/components/CheckCard";
import AllUsers from "views/Users";
import UserCard from "views/Users/components/UserCard";
import Stat from "views/Statistics/index";
import Raffle from "views/Raffle/LotteryBlock";
import WinnersBlock from "views/Raffle/WinnersBlock";
import CheckPattern from "views/Raffle/CheckPattern";
import ItemType from "views/Raffle/ItemType";
import PromoConfigEditor from "views/SiteConfig/PromoConfigEditor";

const dashRoutes = [
  {
    name: "Чеки",
    path: "/dashboard",
    icon: <StatsIcon color="inherit" />,
    authIcon: <StatsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Все чеки",
        path: "/all-checks",
        component: AllChecksData,
        layout: "/dashboard",
      },
      {
        name: "Ручная модерация",
        path: "/manual-moderation",
        component: WaitChecksData,
        layout: "/dashboard",
      },
      {
        name: "Автомодерация",
        path: "/check-pattern",
        component: CheckPattern,
        layout: "/dashboard",
      },
      {
        name: "Типы товаров",
        path: "/item-types",
        component: ItemType,
        layout: "/dashboard",
      },
      {
        name: "Чек",
        path: "/check-card/:id",
        hidden: true,
        component: CheckCard,
        layout: "/dashboard",
      },
    ],
  },

  {
    name: "Пользователи",
    path: "/dashboard",
    icon: <PersonIcon color="inherit" />,
    authIcon: <PersonIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Все пользователи",
        path: "/users",
        component: AllUsers,
        layout: "/dashboard",
      },
      {
        name: "Чеки пользователя",
        hidden: true,
        path: "/user-card/:id",
        component: UserCard,
        layout: "/dashboard",
      },
    ],
  },

  {
    name: "Статистика",
    path: "/dashboard",
    icon: <StatsIcon color="inherit" />,
    authIcon: <StatsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Статистика по промоакции",
        path: "/statistic",
        component: Stat,
        layout: "/dashboard",
      },
    ],
  },

  {
    name: "Розыгрыш",
    path: "/dashboard",
    icon: <StatsIcon color="inherit" />,
    authIcon: <StatsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Проведение розыгрыша",
        path: "/raffle",
        component: Raffle,
        layout: "/dashboard",
      },
      {
        name: "Победители",
        path: "/winners-block",
        component: WinnersBlock,
        layout: "/dashboard",
      },
    ],
  },

  {
    name: "Сайт розыгрыша",
    path: "/dashboard",
    icon: <SettingsIcon color="inherit" />,
    authIcon: <SettingsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Конфигурация промо-акции",
        path: "/config",
        component: PromoConfigEditor,
        layout: "/dashboard",
      },
    ],
  },

  {
    name: "Basic",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    hidden: true,
    component: SignInBasic,
    path: "/login",
    layout: "/auth",
  },
  // {
  //     name: "Basic",
  //     icon: <HomeIcon color='inherit' />,
  //     authIcon: <HomeIcon color='inherit' />,
  //     hidden: true,
  //     component: SignUp,
  //     path: "/reg",
  //     layout: "/auth",
  // },
];

export default dashRoutes;
