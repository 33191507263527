import { Button, Stack, Flex, Editable, EditablePreview, EditableInput, Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { JsonEditor, candyWrapperTheme } from 'json-edit-react';
import useRequestObject from "hooks/useRequestObject";
import "./PromoConfigEditor.css";
import axios from "axios";


const LandingConfigEdit = () => {
  const [configJson, setConfigJson] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdited, setIsEdited] = useState(false);

  const data = useRequestObject(`config-promo/get?promo=${process.env.REACT_APP_PROMO_NAME || 'demo'}`, 'get');
  
  const updateConfigData = async (data) => {
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/config-promo/update`, {
        promoName: process.env.REACT_APP_PROMO_NAME || 'demo', 
        config: configJson
      })
      setIsLoading(false);
      setIsEdited(false);
      console.log('Config data was updated in database');
    } catch (error) {
      console.log('Config was not saved :(')
      console.error(error);
    }
  }



  useEffect(() => {
    setConfigJson(data.responseData.config);
    setIsLoading(data.isLoading);
  }, [data.responseData, data.isLoading])

  return (
    <Flex direction="column" align={{ sm: "center", md: "center" }} pt={{ sm: "125px", md: "75px" }}>
      <Box width="100%" padding={{sm: 0, md: 4}} alignContent={"center"} justifyContent={"center"}>
        <Button disabled={!isEdited} type="submit" alignSelf={"center"} onClick={() =>  updateConfigData(configJson)} w={{ base: "100%", md: "max-content" }} colorScheme="linkedin" isLoading={isLoading}>
          Обновить конфигурацию
        </Button>

        <br/>
        <br/>

        { isLoading && <div>Loading latest config from database...</div>} 
        
        { configJson && !isLoading && <JsonEditor
          data={ configJson }
          setData={ (data) => {
            setIsEdited(true);
            setConfigJson(data);
          }}
          theme={candyWrapperTheme}
          className={"json-editor-cng"}
          maxWidth={1200}
          rootName="config"
        />}

      </Box>
    </Flex>
  );
};

export default LandingConfigEdit;
